.main_container span {
    color: #f78c40;
}

:root {
    --muorange: #FFA500;
    /* Adjust this hex value to match the desired shade of orange */
}

.buttonMuorange {
    background-color: var(--muorange);
    color: white;
    padding: 0.5rem 1.5rem;
    /* 0.5rem for py-2 and 1.5rem for px-6 */
    margin-top: 1.25rem;
    /* my-5 in Tailwind is margin-top and margin-bottom of 1.25rem, adjusting for only top margin */
    margin-bottom: 1.25rem;
    border-radius: 0.375rem;
    /* rounded-md */
    border: none;
    cursor: pointer;
    width: fit-content;
}

p {
    margin: 0;
    padding: 0;
}

.main_container {
    padding: 2rem;
}

.first_view {
    display: flex;
    flex-direction: column-reverse;
}

.fv_img {
    display: block;
    margin: auto;
    width: 20rem;
}

.fv_texts {
    text-align: center;
}

.fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #303030;
}

.fv_heading span {
    font-weight: 600;
    color: #f78c40;
}

.leadcampus {
    background-color: #f78c40;
    color: white;
    padding: 0.5rem 1.25rem;
    border: none;
    margin-top: 1rem;
    border-radius: 5px;
    margin: 1rem 0.25rem;
    margin-left: 0;
}

.fv_tagline {
    margin-top: 0.5rem;
    font-family: "Poppins", sans-serif;
    max-width: 35rem;
    display: block;
    margin: auto;
}

@media only screen and (min-width: 1000px) {
    .first_view {
        max-width: 1300px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .fv_img {
        display: block;
        margin: auto;
        width: 35rem;
    }

    .fv_texts {
        text-align: left;
        width: 50%;
    }

    .fv_heading {
        font-family: "Noto Sans", sans-serif;
        font-size: 4.55rem;
        line-height: 5rem;
        font-weight: 600;
    }

    .fv_tagline {
        font-size: 1rem;
        margin: 0;
        margin-top: 1rem;
    }
}

/* Second View Styles */

.second_view {
    margin-top: 3rem;
}

.sv_pheading {
    font-size: 1.5rem;
    font-weight: 600;
    font-variant: small-caps;
}

.sv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 3rem;
    font-weight: 600;
    color: #303030;
}

@media only screen and (max-width: 767px) {
    .sv_heading {
        font-family: "Noto Sans", sans-serif;
        font-size: 2rem;
        font-weight: 600;
        color: #303030;
    }
}

.sv_heading span {
    color: #f78c40;
}

.d_number {
    background-color: #f78c40;
    color: white;
    font-weight: 600;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 1000px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: "Noto Sans", sans-serif;
}

.d_text {
    font-family: "Poppins", sans-serif;
}

.d_heading span {
    font-weight: 500;
    font-size: 1.1rem;
    font-family: "Noto Sans", sans-serif;
}

.d_text span {
    font-weight: 600;
}

.d_main_container {
    margin-top: 1rem;
}

.d_container {
    margin-top: 1.5rem;
}

.d_video_container {
    margin-top: 2rem;
}

@media only screen and (min-width: 1000px) {
    .second_view {
        max-width: 1300px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4rem;
    }

    .sv_img {
        display: block;
        margin: auto;
        width: 30rem;
    }

    .sv_heading1 {
        font-family: "Noto Sans", sans-serif;
        font-weight: 600;
        align-items: center;
        justify-content: center;
    }

    .sv_tagline {
        font-size: 1rem;
        width: 80%;
    }

    .sv_pheading {
        font-size: 1.3rem;
        font-weight: 600;
        font-variant: small-caps;
    }

    .d_video_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .d_video iframe {
        width: 35rem;
        height: 19rem;
        margin-right: 3rem;
    }

    .d_main_container {
        margin-top: 0;
        width: 50%;
    }

    .d_text {
        font-size: 1rem;
    }
}

/* Third Section Styles */

.third_view_container {
    padding-top: 2rem;
}

.tv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #303030;
}

.tv_heading span {
    color: #f78c40;
}

.story_img {
    border-radius: 5px;
}

.tv_story {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.story_name {
    font-family: "Noto Sans", sans-serif;
    margin-top: 1rem;
    font-size: 1.3rem;
}

.story_img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.read_more {
    margin-top: 1rem;
    font-family: "Noto Sans", sans-serif;
    color: #f78c40;
}

.tv_story_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
}

@media only screen and (min-width: 1000px) {
    .third_view {
        max-width: 1300px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4rem;
    }

    .tv_heading {
        font-family: "Noto Sans", sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .tv_tagline {
        font-size: 1.1rem;
        font-family: "Poppins", sans-serif;
        width: 70%;
    }

    .tv_story_container {
        display: flex;
    }
}

/* FAQ Styles */

.faq_view_container {
    background-color: #ffffff;
    padding-top: 2rem;
}

.faq_view {
    margin-top: 2rem;
}

@media only screen and (min-width: 1000px) {
    .faq_view_container {
        max-width: 1300px;
        margin: auto;
        background-color: #ffffff;
    }
}

/* Logo Generator Styles */

.generator_view {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
}

.gv_img {
    width: 20rem;
}

.gv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #303030;
}

.gv_heading span {
    color: #f78c40;
}

.createlogo {
    background-color: #f78c40;
    color: white;
    padding: 0.5rem 1.25rem;
    border: none;
    margin-top: 2rem;
    border-radius: 5px;
}

.redirection_container {
    background-color: #f78c40;
    color: white;
    border-radius: 10px;
    display: inline-block;
    padding: 1rem;
}

.redirection_tagline {
    margin-top: 0.25rem;
    font-family: "Poppins", sans-serif;
}

.redirection_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
}

.redirection_button {
    background-color: #ffffff;
    color: #f78c40;
    margin-top: 1rem;
    border-radius: 5px;
    padding: 0.5rem 1.95rem;
}

.gv_tagline {
    margin-top: 0.5rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
}

@media only screen and (min-width: 1000px) {
    .generator_view {
        max-width: 1300px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem;
    }

    .gv_img {
        display: block;
        margin: auto;
        width: 35rem;
    }

    .gv_heading {
        font-family: "Noto Sans", sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
    }

    .gv_tagline {
        font-size: 1rem;
        width: 70%;
        margin-bottom: 1rem;
    }
}