.main_container {
  /* padding: 2rem; */
}

.first_view_container {
  padding: 2rem;
  min-height: 45vh;
}

.lastdate {
  font-family: "Poppins", sans-serif;
  margin-top: 0.5rem;
  color: #363636;
  font-weight: 500;
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.75rem;
  letter-spacing: 0.1rem;
  color: #ff8125;
  margin-bottom: 1rem;
  text-align: center;
}

.fv_heading span {
  color: #ffffff;
  text-shadow: -2px -2px 0 #343537, 2px -2px 0 #343537, -2px 2px 0 #343537,
    2px 2px 0 #343537;
}

.fv_tagline {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #404040;
  text-align: center;
}

.fv_image {
  width: 20rem;
  display: block;
  margin: auto;
}

.register {
  background-color: #ff8125;
  border-radius: 8px;
  padding: 0.5rem 1.75rem;
  color: white;
  display: block;
  margin: auto;
  margin-top: 1rem;
}

.supporters {
  color: #404040;
  margin-top: 1rem;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supporters span {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-right: 1rem;
}

.supporter {
  width: 4rem;
  margin-right: 1rem;
  border-radius: 50px;
  box-shadow: 0 1px 12px 0 rgba(193, 195, 211, 0.37);
}

.registerfixed {
  background-color: #fff;
  border: 2px solid #f33e41;
  border-radius: 8px;
  padding: 0.5rem 1.75rem;
  color: #f33e41;

  margin-top: 1rem;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 411;
}

@media only screen and (min-width: 1000px) {
  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .supporters {
    justify-content: flex-start;
  }

  .fv_heading {
    text-align: left;
    font-size: 5rem;
  }

  .fv_image {
    width: 30rem;
  }
  .fv_tagline {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    text-align: left;
    width: 90%;
  }

  .lastdate {
    text-align: left;
  }

  .fv_texts {
    width: 35vw;
  }
}

.sv_heading {
  color: #ff8125;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.sv_tagline {
  font-family: "Poppins", sans-serif;
}

.second_view_container {
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sv_points {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.svp_heading {
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
}

.svp_text {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.svp_text span {
  font-weight: 500;
}

.sv_image {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.sv_point {
  text-align: center;
  margin: 1rem 0rem;
}

@media only screen and (min-width: 1000px) {
  .second_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  .buttons {
    display: flex;
  }

  .register {
    margin: 0rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }

  .sv_heading {
    font-size: 3rem;
    text-align: left;
  }

  .sv_tagline {
    font-family: "Poppins", sans-serif;
    width: 80%;
    text-align: center;
  }

  .sv_points {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 3rem;
  }

  .sv_point {
    text-align: center;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .svp_heading {
    font-family: "Raleway", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .svp_text {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
}

/* Winner View Container */

.winner_view_container {
  padding: 2rem;
}

.winner {
  max-width: 20rem;
}

.sv_tagline b {
  font-weight: 500;
  color: #404040;
}

.winners {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
.winners>div{
    display: flex;
     gap: 1.5rem;
     flex-wrap: wrap;
}
.winners >h1{
    font-size: 27px;
    font-weight: 600;
}
.winner_image:hover {
  transform: scale(1.1);
}

.winner_image {
  width: 20rem;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.winner_team {
  margin-top: 0.5rem;
  color: #404040;
  font-family: "Noto Sans", sans-seif;
  font-size: 1.5rem;
  font-weight: 400;
}

.winner_name {
  color: #ff8125;
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  margin-top: -0.25rem;
  font-weight: 400;
}

@media only screen and (max-width: 1000px) {
  .winner_view_container {
    text-align: center;
  }

  .winners {
    justify-content: center;
  }
}

@media only screen and (min-width: 1000px) {
  .winner_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.third_view {
  margin: 2rem;
  text-align: center;
}

.tv_heading {
  color: #ff8125;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.tv_tagline {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

li {
  margin-top: 0.5rem;
}

@media only screen and (min-width: 1000px) {
  .third_view_container {
    display: flex;
    flex-direction: row;

    max-width: 1300px;
    margin: auto;
  }

  .third_view {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 45vw;
  }

  .tv_heading {
    color: #ff8125;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    font-size: 2.25rem;
    margin-bottom: 0.25rem;
  }

  .svp_text a {
    text-decoration: underline;
  }

  .tv_tagline {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
}
