
.benefitsEnabler{
    width: 100%;
    display: grid;
    place-items: center;
    gap: 1.5rem;
}


.benefitsEnabler h1{
    color: #9765FF;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.4px;
    letter-spacing: -2.88px;
}

.benefitsEnabler .benefitsEnablerHeading{
    background-image: url('../../assests/Underline.webp');
    background-repeat: no-repeat;
    background-position: -10px 52px;
    background-size: 200px;
}

.benefitsEnabler h1 span{
    color: #FF7D40;
    text-align: center;
}

.benefitsEnablerBenefits{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.benefitsEnablerBenefits h1{
    margin-bottom: 1rem;
    color: #383838;
}
.benefitsEnablerBenefits div div{
    display: flex; 
    align-items:center; 
    gap: 32px;
    padding-bottom: 10px;
}

.benefitsEnablerBenefits div div p{
    width: 435px;
    color: #696984;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

@media (width<1300px){

    .benefitsEnabler h1{
        font-size: 40px;
    } 

    .benefitsEnablerBenefits{
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        /* border-radius: 1rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, .25); */
    }

    .benefitsEnablerBenefits h1{
        text-align: center;
    }

    .benefitsEnablerBenefits:nth-child(odd){
        flex-direction: column-reverse;
    }   
}

@media (width<650px){
    .benefitsEnabler h1{
        font-size: 35px;
    }
    .benefitsEnablerBenefits{
        padding: 1rem 0;
    }
    .benefitsEnablerBenefitsImage{
        width: 50%;
    }
    .benefitsEnablerBenefits div h1{
        font-size: 20px;
        letter-spacing: .05px;
        margin-bottom: 0;
    }
    .benefitsEnablerBenefits div div p{
        width: 250px;
        font-size: 16px;
    }
    .benefitsEnablerBenefits div div img{
        width: 60px;
    }
}