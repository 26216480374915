* {
  padding: 0;
  margin: 0;
}

.first_view_container {
  padding: 2rem;
  background-color: #ffffff;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_img {
  width: 18rem;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.event_partners_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 500;
  margin-right: 1rem;
}

.partners {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 1rem 0;
}

.partner_image {
  height: 2.5rem;
  margin-right: 0.5rem;
  display: block;
}

.detailsbtn {
  margin-top: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    padding: 3rem 3rem 0;
  }

  .partners {
    justify-content: left;
    align-self: left;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 28rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1rem;
    width: 80%;
  }

  .detailsbtn {
    margin-right: 1rem;
  }
}

/* Second Section Styles */

.second_view_container {
  padding: 2rem;
  background-color: #ffffff;
}

.second_view {
  margin-top: 2rem;
}

.sv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.theme {
  margin-top: 0.5rem;
  font-size: 1.05rem;
  color: #404040;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
}

.sv_heading > span {
  color: #f78c40;
}

.sv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.topic_card {
  margin: 2rem 0rem;
}

.topic_heading {
  margin-top: 0.75rem;
}

.card_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: #404040;
  width: 80%;
}

.card_description {
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
  margin-top: 0.75rem;
}

.topic {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #404040;
}

.cards_container_desktop {
  display: none;
}

.cards_container_mobile {
  display: block;
}

.examples li {
  margin: 0.5rem 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.examples span {
  font-weight: 600;
}

.card_heading span {
  color: #f78c40;
}

.create {
  background-color: #f6842c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
}

.nomination_button {
  background-color: white;
  color: #f78c40;
  border: solid;
  padding: 0.4rem 1.5rem;
  font-size: 0.9rem;
  border: 2px solid #f78c40;
  border-radius: 5px;
  margin-top: 1rem;
}

.fv_buttons {
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .fv_buttons {
    /* margin-top: 1rem; */
    display: flex;
    flex-direction: row;
  }

  .nomination_button {
    margin-left: 0rem;
  }
  .second_view_container {
    max-width: 1300px;
    margin: auto;
  }

  .second_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card_heading {
    width: 80%;
    font-size: 1.75rem;
  }

  .topic_card {
    max-width: 500px;
    margin: 0 75px;
  }

  .second_view {
    margin-top: 3rem;
  }

  .sv_texts {
    width: 50%;
  }

  .theme {
    font-size: 1.4rem;
  }

  .cards_container_desktop {
    display: block;
  }

  .cards_container_mobile {
    display: none;
  }

  .sv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .sv_tagline {
    width: 80%;
    margin-top: 0.5rem;
    font-family: "Poppins", sans-serif;
  }

  .card_description {
    width: 90%;
    font-size: 1rem;
  }

  .topic_card_android {
    margin-top: 7rem;
  }

  .nomination_button {
    margin-left: 0.5rem;
    margin-top: 0rem;
  }
}

@media only screen and (min-width: 1300px) {
  .second_view_container {
    padding: 0rem;
    background-color: #ffffff;
  }
}

/* Third Section Styles */

.third_view_container {
  padding: 2rem;
  background-color: #ffffff;
}

.third_view {
  margin-top: 2rem;
}

.tv_heading {
  font-family: "Noto Sans", sans-serif;
  color: #404040;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.tv_text {
  margin-top: 2rem;
}

.tv_heading span {
  color: #f78c40;
}

.tv_list_item {
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .third_view_container {
    max-width: 1300px;
    margin: auto;
  }

  .third_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8rem;
  }

  .tv_text {
    width: 40%;
  }

  .tv_heading {
    font-size: 2.25rem;
  }

  .tv_texts {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 1300px) {
  .third_view_container {
    padding: 0rem;
    background-color: #ffffff;
  }
}

/* Fourth Section Styles */
.fourth_view_container {
  padding: 2rem;
  background-color: #ffffff;
  /* padding-top: 2rem; */
}

/* .fourth_view {
  margin-top: 2rem;
} */

.fr_heading {
  font-family: "Noto Sans", sans-serif;
  color: #404040;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.fr_text {
  margin-top: 2rem;
}

.fr_heading span {
  color: #f78c40;
}

.fr_heading {
  font-size: 2.5rem;
}

.fr_tagline {
  font-family: "Poppins";
}

.ytplayer {
  margin-top: 2rem;
  border-radius: 500px;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 768px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .fourth_view_container {
    max-width: 1300px;
    margin: auto;
    /* margin-top: 8rem; */
  }

  .fourth_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .left_side {
    width: 40%;
  }

  .fr_heading {
    font-size: 3.5rem;
  }

  .fr_tagline {
    font-family: "Poppins";
    width: 80%;
  }
}

@media only screen and (min-width: 1300px) {
  .fourth_view_container {
    padding: 0rem;
  }
}

/* FAQ Styles */

.faq_view_container {
  padding: 2rem;
  background-color: #ffffff;
  padding-top: 2rem;
}

.faq_view {
  margin-top: 2rem;
}

@media only screen and (min-width: 1000px) {
  .faq_view_container {
    max-width: 1300px;
    margin: auto;
    padding: 2rem;
    background-color: #ffffff;
    /* padding-top: 6rem; */
  }
}

/* Judge Panel View */
.judge_view_container {
  padding: 2rem;
  background-color: #ffffff;
  padding-top: 2rem;
}

.judge_view_view {
  margin-top: 2rem;
}

.jv_heading {
  font-family: "Noto Sans", sans-serif;
  color: #404040;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.jv_text {
  margin-top: 0rem;
  font-family: "Poppins", sans-serif;
}

.jv_heading span {
  color: #f78c40;
}

@media only screen and (min-width: 1000px) {
  .judge_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 2rem;
  }

  .judges_card_container {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
  }

  .jv_text {
    margin-top: 0;
    width: 40%;
  }

  .jv_heading {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 1300px) {
  .judge_view_container {
    padding: 0rem;
  }
}

.detailsbtn {
  background-color: #f78c40;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 0.5rem 1.25rem;
}

.yt_video {
  width: 80vw;
  min-height: 30vh;
  border-radius: 8px;
  margin: 5vh 0;
}

@media only screen and (min-width: 1000px) {
  .yt_video {
    width: 30vw;
    min-height: 35vh;
    border-radius: 8px;
    margin: 5vh 0;
  }
}

/* Videos Container View */

.videos_view_container {
  padding: 2rem;
  background-color: #ffffff;
  padding-top: 2rem;
}

.videos_view {
  margin-top: 2rem;
}

.vv_heading {
  font-family: "Noto Sans", sans-serif;
  color: #404040;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.vv_tagline {
  margin-top: 0rem;
  font-family: "Poppins", sans-serif;
}

.vv_heading span {
  color: #f78c40;
}

.videos_container {
  margin-top: 1rem;
}

.video {
  position: relative;
  width: 100%;
  border-radius: 8px;
  margin: 2rem 0;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.ytvideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media only screen and (min-width: 1000px) {
  .videos_container {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2rem;
    justify-content: space-between;
  }

  .videos_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 3rem;
  }

  .vv_tagline {
    margin-top: 0;
    width: 40%;
  }

  .vv_heading {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 1300px) {
  .videos_view_container {
    padding: 0rem;
  }
}
