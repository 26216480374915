.hswiper {
  height: 550px;

  .swiper-button-prev {
    color: #f78c40;
  }

  .swiper-button-next {
    color: #f78c40;
  }

  .swiper-pagination-bullet-active {
    background-color: #f78c40 !important;
  }
}
