.mentor_card_container {
  margin: 1rem auto 0;
  max-width: 400px;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
  width: 100%;
  /* height: 15rem; */
}

.mentorimg {
  object-fit: cover;
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
}

.comingsoon {
  background-color: #f78c40;
  color: white;
  margin-top: 1rem;
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
}

.mentor_name {
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.box {
  display: flex;
  justify-content: space-between;
}

.mentor_interest {
  opacity: 0.5;
  font-size: 0.9rem;
  text-align: right;
  font-weight: 600;
  width: 150px;
}

.mentor_source {
  opacity: 0.5;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 600;
  width: 150px;
}

.mentor_designation {
  width: 90%;
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
}

.linkedinimg {
  height: 1.75rem;
  width: 1.75rem;
  margin-top: 0.5rem;
}

.textdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}