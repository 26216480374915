.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  width: 350px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  text-align: center;
}

.logo {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.details {
  flex: 1 1 auto;
}

.details h3 {
  font-size: 24px;
  margin-bottom: 8px;
}


.details ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.details li {
  font-size: 16px;
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.apply {
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 16px;
  background-color: #294675;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply:hover {
  background-color: #375688;
}


.hiring_title {
  font-size: 1.5rem;
  font-family: "Noto Sans";
  font-weight: 600;
  color: #294675;
  margin-bottom: 10px;
  text-align: center;
}