.Overview_container_head {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    gap: 2rem;
    position: relative;
}

.Overview_container_head hr {
    width: 100%;
    background-color: #1C1CF0;
    height: .25rem;
}

.Overview_container_head p {
    position: absolute;
    background-color: #F5F5F5;
    padding: 0 1.5rem;
    color: #000;
    font-size: 3rem;
    font-weight: 900;
    width: max-content;
}

.Overview_desc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
}

.Overview_desc .course {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    border-radius: .5rem;
    width: 50%;
    height: 16rem;
    font-weight: 500;
    font-size: .9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5rem;
}

.Overview_desc .course .Course_desc {
    display: flex;
    gap: .5rem;
}

.Overview_desc .course .Course_desc img {
    width: 2.5rem;
    height: 2.5rem;
}

.Overview_desc .course .stage {
    color: #E4311C;
    font-weight: 900;
    display: flex;
    align-items: center;
    gap: .5rem;
}

@media(width<=1280px) {
    .Overview_desc {
        gap: 1rem;
    }

    .Overview_desc .course {
        width: 100%;
        height: 18rem;
    }
}

@media(width<=1024px) {
    .Overview_desc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .Overview_desc .course {
        height: 14rem;
    }
}

@media(width<=640px) {
    .Overview_desc {
        grid-template-columns: repeat(1, 1fr);
        padding: .5rem;
    }

    .Overview_desc .course {
        height: 12rem;
    }

    .Overview_container_head {
        padding-bottom: 2rem;
    }

    .Overview_container_head p {
        font-size: 2.5rem;
    }
}

@media(width<=300px){
    .Overview_container_head p {
        font-size: 1.5rem;
    }

    .Overview_desc .course {
        font-size: .7rem;
    }
}