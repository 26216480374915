.About {
    padding: 1rem 0;
}

.Overview_container_head {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    gap: 2rem;
    position: relative;
}

.Overview_container_head hr {
    width: 100%;
    background-color: #1C1CF0;
    height: .25rem;
}

.Overview_container_head p {
    position: absolute;
    background-color: #F5F5F5;
    padding: 0 1.5rem;
    color: #000;
    font-size: 3rem;
    font-weight: 900;
    width: max-content;
}

.WhatIsPathway_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 1rem;
}

.WhatIsPathway_img img {
    height: 20rem;
}

.WhatIsPathway_desc {
    width: 50%;
    font-size: 1.2rem;
}

.WhatIsPathway_desc span {
    font-weight: 800;
}

@media(width<=820px) {
    .WhatIsPathway_main {
        flex-direction: column;
    }

    .WhatIsPathway_img img {
        height: 15rem;
    }

    .WhatIsPathway_desc {
        width: 80%;
    }
}

@media(width<=500px) {
    .Overview_container_head p {
        font-size: 2.5rem;
    }

    .WhatIsPathway_desc {
        width: 100%;
        font-size: 1rem;
    }
}

@media(width<=300px) {
    .Overview_container_head {
        padding: 2rem;
    }

    .WhatIsPathway_img img {
        height: 10rem;
    }

    .WhatIsPathway_desc {
        font-size: .8rem;
    }

    .Overview_container_head p {
        font-size: 1.5rem;
        padding: 0;
    }
}