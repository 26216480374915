* {
  padding: 0;
  margin: 0;
}

.first_view_container,
.second_view_container {
  padding: 2rem;
  background-color: #ffffff;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_img {
  width: 18rem;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.event_partners_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 500;
  margin-right: 1rem;
}

.partners {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 1rem 0;
}

.partner_image {
  height: 2rem;
  margin-right: 0.5rem;
  display: block;
}

.detailsbtn {
  margin-top: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container,
  .second_view_container {
    padding: 3rem 3rem 0;
  }

  .partners {
    justify-content: left;
    align-self: left;
  }

  .first_view,
  .second_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 35rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1rem;
    width: 80%;
  }

  .detailsbtn {
    margin-right: 1rem;
  }
}

.third_view {
  margin-top: 2rem;
}

.image_container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.image_container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.image_container img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  height: 15rem;
  animation: move-left 15s linear infinite;
}

@keyframes move-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.tv_heading {
  font-family: "Noto Sans", sans-serif;
  color: #404040;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.tv_text {
  margin-top: 2rem;
}

.tv_heading span {
  color: #f78c40;
}

.tv_tagline {
  font-family: "Poppins";
  max-width: 35rem;
}

.tv_listing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

/* Assuming these styles are defined using CSS Modules */
.tv_card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 25rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.tv_text {
  margin-top: 1rem;
}

.team_name,
.team_code,
.team_lead,
.team_place,
.team_members {
  margin: 0.25rem 0;
}

.team_name span,
.team_code span,
.team_lead span,
.team_place span {
  color: #f78c40;
}

.team_link {
  background-color: #f78c40;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.team_link:hover {
  background-color: #e57329;
}

.third_view_container {
  max-width: 1300px;
  margin: auto;
}

.third_view {
  display: flex;
  flex-direction: column;
}
