.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-family: sans-serif;
  width: 20rem;
  border: 2px solid #1e3760;
}
h2 {
  margin: 0;
}

.card_logo {
  width: 150px;
  height: 100px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.card_title {
  font-size: 1.75rem;
  font-family: "Noto Sans";
  font-weight: 600;
  color: #294675;
  margin-bottom: 10px;
  text-align: center;
}

.card_info {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  margin-top: -0.25rem;
}

.card_label {
  color: #404040;
  white-space: nowrap;
  font-weight: bold;
  margin-right: 5px;
  font-family: "Noto Sans";
}

.card_value {
  margin-right: 10px;
  font-family: "Poppins";
}

.card_buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.card_button {
  background-color: #1e3760;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 1rem;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.card_button_outline {
  color: #1e3760;
  border: 2px solid #1e3760;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 1rem;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.card_button:hover {
  background-color: #375688;
}
