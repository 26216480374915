* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h2 {
  margin: 5%;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
}

.ktimeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 40px auto;
  position: relative;
}

.ktimeline__event {
  margin-bottom: 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  -ms-flex-item-align: center;
  align-self: center;
  width: 50vw;
}

.ktimeline__event:nth-child(2n + 1) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.ktimeline__event:nth-child(2n + 1) .ktimeline__event__date {
  border-radius: 0 6px 6px 0;
}

.ktimeline__event:nth-child(2n + 1) .ktimeline__event__content {
  border-radius: 6px 0 0 6px;
}

.ktimeline__event:nth-child(2n + 1) .ktimeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #ffc773;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 2s ease-in-out;
  animation: fillTop 2s forwards 2s ease-in-out;
}

.ktimeline__event:nth-child(2n + 1) .ktimeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #ffc773;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: fillLeft 2s forwards 2s ease-in-out;
  animation: fillLeft 2s forwards 2s ease-in-out;
}

.ktimeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #fa8322;
  letter-spacing: 1.5px;
  margin-bottom: 0.5rem;
}

.ktimeline__event__content {
  padding: 20px;
  -webkit-box-shadow: 0 15px 30px -6px rgba(187, 187, 187, 0.25),
    0 9px 18px -9px rgba(0, 0, 0, 0.3), 0 -6px 18px -4px rgba(0, 0, 0, 0.025);
  box-shadow: 0 15px 30px -6px rgba(187, 187, 187, 0.25),
    0 9px 18px -9px rgba(0, 0, 0, 0.3), 0 -6px 18px -4px rgba(0, 0, 0, 0.025);
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 0 6px 6px 0;
}

.ktimeline__event__date {
  color: #ffc773;
  font-size: 2.5rem;
  font-weight: 600;
  background: #fa8322;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 3rem;
  border-radius: 6px 0 0 6px;
}

.ktimeline__event__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fa8322;
  padding: 20px;
  -ms-flex-item-align: center;
  align-self: center;
  margin: 0 20px;
  background: #f8f8f8;
  border-radius: 100%;
  width: 40px;
  -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 40px;
  position: relative;
}

.ktimeline__event__icon i {
  font-size: 32px;
}

.ktimeline__event__icon img {
  width: 2.5rem;
  position: absolute;
  height: auto;
  height: 2.5rem;
  opacity: 0.6;
}

.ktimeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f8f8f8;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 2s ease-in-out;
  animation: fillTop 2s forwards 2s ease-in-out;
}

.ktimeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f8f8f8;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: fillLeftOdd 2s forwards 2s ease-in-out;
  animation: fillLeftOdd 2s forwards 2s ease-in-out;
}

.ktimeline__event__description {
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.ktimeline__event__description a {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.ktimeline__event--type2:after {
  background: #43d0fc;
}

.ktimeline__event--type2 .ktimeline__event__date {
  color: rgb(250, 251, 252);
  background: #43d0fc;
}

.ktimeline__event--type2:nth-child(2n + 1) .ktimeline__event__icon:before,
.ktimeline__event--type2:nth-child(2n + 1) .ktimeline__event__icon:after {
  background: #87bbfe;
}

.ktimeline__event--type2 .ktimeline__event__icon {
  background: #f8f8f8;
  color: #43d0fc;
}

.ktimeline__event--type2 .ktimeline__event__icon:before,
.ktimeline__event--type2 .ktimeline__event__icon:after {
  background: #87bbfe;
}

.ktimeline__event--type2 .ktimeline__event__title {
  color: #43d0fc;
}

.ktimeline__event--type3:after {
  background: #24b47e;
}

.ktimeline__event--type3 .ktimeline__event__date {
  color: #ffffff;
  background-color: #24b47e;
}

.ktimeline__event--type3:nth-child(2n + 1) .ktimeline__event__icon:before,
.ktimeline__event--type3:nth-child(2n + 1) .ktimeline__event__icon:after {
  background: #aff1b6;
}

.ktimeline__event--type3 .ktimeline__event__icon {
  background: #f8f8f8;
  color: #24b47e;
}

.ktimeline__event--type3 .ktimeline__event__icon:before,
.ktimeline__event--type3 .ktimeline__event__icon:after {
  background: #aff1b6;
}

.ktimeline__event--type3 .ktimeline__event__title {
  color: #24b47e;
}

.ktimeline__event--type4:after {
  background: #606060;
}

.ktimeline__event--type4 .ktimeline__event__date {
  color: #fff;
  background-color: #606060;
}

.ktimeline__event--type4:nth-child(2n + 1) .ktimeline__event__icon:before,
.ktimeline__event--type4:nth-child(2n + 1) .ktimeline__event__icon:after {
  background: #828282;
}

.ktimeline__event--type4 .ktimeline__event__icon {
  background: #f8f8f8;
  color: #ffff;
}

.ktimeline__event--type4 .ktimeline__event__icon:before,
.ktimeline__event--type4 .ktimeline__event__icon:after {
  background: #828282;
}

.ktimeline__event--type4 .ktimeline__event__title {
  color: #606060;
}

.ktimeline__event:last-child .ktimeline__event__icon:before {
  content: none;
}

.ktimeline__event--type5 .ktimeline__event__date {
  color: #ffffff;
  background-color: #f0db4f;
}

.ktimeline__event--type5.ktimeline__event--type4 {
  background: #f0db4f;
}

.ktimeline__event--type5:nth-child(2n + 1) .ktimeline__event__icon:before,
.ktimeline__event--type5:nth-child(2n + 1) .ktimeline__event__icon:after {
  background: #fced8b;
}

.ktimeline__event--type5 .ktimeline__event__icon {
  background: #ffffff;
  color: #f0db4f;
}

.ktimeline__event--type5 .ktimeline__event__icon:before,
.ktimeline__event--type5 .ktimeline__event__icon:after {
  background: #fced8b;
}

.ktimeline__event--type5 .ktimeline__event__title {
  color: #f0db4f;
}

.ktimeline__event--type6 .ktimeline__event__date {
  color: #ffffff;
  background-color: #35a898;
}

.ktimeline__event--type6.ktimeline__event--type4 {
  background: #35a898;
}

.ktimeline__event--type6:nth-child(2n + 1) .ktimeline__event__icon:before,
.ktimeline__event--type6:nth-child(2n + 1) .ktimeline__event__icon:after {
  background: #35a898;
}

.ktimeline__event--type6 .ktimeline__event__icon {
  background: #ffffff;
  color: #35a898;
}

.ktimeline__event--type6 .ktimeline__event__icon:before,
.ktimeline__event--type6 .ktimeline__event__icon:after {
  background: #35a898;
}

.ktimeline__event--type6 .ktimeline__event__title {
  color: #35a898;
}

@media (max-width: 786px) {
  .ktimeline__event {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .ktimeline__event__content {
    width: 100%;
  }

  .ktimeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ktimeline__event__icon:before,
  .ktimeline__event__icon:after {
    display: none;
  }

  .ktimeline__event__date {
    display: none;
    border-radius: 0;
    text-align: center;
    padding: 20px;
  }

  .ktimeline__event:nth-child(2n + 1) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .ktimeline__event:nth-child(2n + 1) .ktimeline__event__date {
    border-radius: 0;
    padding: 20px;
  }

  .ktimeline__event:nth-child(2n + 1) .ktimeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
}

@-webkit-keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@-webkit-keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@-webkit-keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

/* ktimeline Styles */
.lni-cake {
  width: 4rem;
}

.third-section {
  margin: 7vh 0;
  /* margin-bottom: 15vh; */
}

.tsheading {
  text-align: center;
  color: #404040;
  font-size: 2.1rem;
  font-weight: 600;
}

@media only screen and (min-width: 1000px) {
  .tsheading {
    font-size: 3rem;
    margin-bottom: 4vh;
  }
}

.inputfield {
  margin-top: 2rem;
  width: 20rem;
  padding: 0.4rem 2.25rem;
}

.submit-btn {
  background-color: #f6832a;
  color: white;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0.45rem 2.25rem;
}

@media only screen and (min-width: 1000px) {
  .inputfield {
    margin-top: 2rem;
    width: 20rem;
    padding: 0.4rem 2.25rem;
    border-width: unset;
  }

  .submit-btn {
    background-color: #f6832a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.45rem 2.25rem;
    margin: 0px 10px;
  }
}

.ktimeline__event__register_btn {
  padding: 1rem 0rem 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ktimeline__event__register {
  padding: 0.5rem 1rem;
  background: #f6832a;
  /* margin-top: 0.5rem; */
  border-radius: 8px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}