.kkemLearningFest {
  background-image: linear-gradient(0deg, #B80000 50%, #E60000 50%);
  background-size: 10px 25px;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
}

.kkemLearningFestFooter{
  width: 100%;
}