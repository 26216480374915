.container{
    display: flex;
    width: 100%;
    justify-content: center;      
    flex-wrap: wrap;  
}

.table{
    margin: 20px;
    padding: 30px;
    border-radius: 15px;
    background-color: rgb(248, 252, 255);
    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}
.h1{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    padding:0px;
    width: 100%;
    margin: 0;
}
/* h1:first-child{
    margin-top: 15px;
} */
h1:nth-child(2){
    margin-bottom:30px;
}
.h2{
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    margin-top: 15px;
    margin-bottom:30px;
}
table,.tableContainer   {
    width: 100%;
    border-spacing: 10px;
    word-break: break;
    min-width: 500px;
    width: 600px;
}
th{
    text-align: center;
    font-weight: 600;
}
tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.20) ;
}
.clear{
    padding:10px;
}
tbody td{
    text-align: center;
    width: fit-content;
}
table tr .td,.th .name{
    padding:0 20px;
}
.container .table .tableContainer  tbody td:nth-child(2),th:nth-child(2){
    text-align: left;
}
.container .table .tableContainer  tbody tr:nth-child(-n+3){
    background-color: rgb(255, 191, 0);
    border-radius: 10px;
    color: white;
}
.side{
    text-align: left;
}
.container .table .tableContainer  tr:nth-child(-n+3) td{ 
    font-size: 16px;
    font-weight: 700;
    /* text-align: center; */
}
