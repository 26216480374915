* {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

.main_container {
  margin: 1rem;
}

.fv_texts {
  text-align: center;
  margin: 2rem;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .main_container {
    max-width: 1000px;
    margin: auto;
    margin-top: 3.5rem;
  }
}

/* Calendar View */

.calendar_view_container {
  margin: 2rem;
  border:none;
}

.calendar_view {
  margin-top: 3rem;
}

.iframe_container {
  margin-top: 1rem;
}

@media only screen and (min-width: 1000px) {
  .calendar_view_container {
    max-width: 1300px;
    display: block;
    margin: auto;
  }

  .calendar_view {
    margin: 2rem;
  }

  .cv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 700;
    color: #303030;
  }
  .iframe_container {
    margin-top: 2rem;
  }
  .cv_texts {
    width: 60%;
  }
}
