.container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.journey_container {
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  column-gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.layout {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-family: sans-serif;
  max-width: 600px;
  margin: 1rem;
}

.c_texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.ct_heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #eb8e38;
  font-family: "Poppins";
  text-align: center;
  margin-bottom: 0.5rem;
}

.ct_text {
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
  text-align: center;
  font-family: "Poppins";
  margin-bottom: 0.5rem;
  max-width: 35rem;
  margin-bottom: 2rem;
}

.heading {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  padding-top: 10px;
  font-weight: 600;
  color: #eb8e38;
  font-size: 18px;
  font-family: "Poppins";
}

.list {
  padding-left: 15px;
  display: flex;
}
.list li {
  list-style-type: disc;
  padding-left: 10px;
}
.list img {
  width: 30%;
  height: auto;
  margin: 0px 10px;
  object-fit: cover;
}
.list ul {
  width: auto;
  font-family: "Poppins", sans-serif;
}
@media only screen and (max-width: 700px) {
  .layout {
    width: 90%;
    height: auto;
    margin: auto;
  }
  .list img {
    display: none;
  }

  .list ul {
    padding: 5px;
  }
}
