.community_card_container {
  margin: 1rem auto 0;
  max-width: 300px;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 15px;
  border-radius: 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.communityimg {
  width: 4rem;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 2rem;
}

.community_name {
  margin-top: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.community_interest {
  color: gray;
  font-size: 0.9rem;
  text-align: right;
  font-weight: 600;
  width: 150px;
}

.community_source {
  opacity: 0.5;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 600;
  width: 150px;
}

.community_designation {
  width: 90%;
  margin-top: 0.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
}

.linkedinimg {
  height: 1.75rem;
  width: 1.75rem;
  margin-top: 0.5rem;
}

.textdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}