@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Rubik:wght@400;500;600;700;800;900&display=swap');

.mainpage {
    height: 100vh;
    width: 100%;
    background: #F5F5F5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.First {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 1rem; */
}

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 4rem;
    gap: 1rem;
}

.Container_image_one {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container_desc {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;
}

.Container_image_one img {
    height: 30rem;
    object-fit: contain;
}

.Container_img_two {
    width: fit-content;
    padding: .8rem .5rem;
    border-radius: .5rem;
    font-size: 1.5rem;
    background-color: #232A34;
    color: #fff;
}

.Container_head {
    font-weight: 900;
    font-size: 4.3rem;
    font-family: 'Rubik', sans-serif;
    line-height: 5rem;
    width: max-content;
    width: 100%;
}

.Head_two {
    background-color: transparent;
    color: #DC280B;
    font-family: 'Rubik', sans-serif;
}

.Container_button_one {
    padding: .5rem 1rem;
    border-radius: .5rem;
    background-color: #DC280B;
    font-weight: 700;
    border: none;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.Container_button_one img {
    background-color: transparent;
}

.Container_button_two {
    padding: .5rem 1rem;
    border-radius: .5rem;
    border-radius: 8px;
    background: #EEEEEE;
    font-weight: 900;
    border: none;
    color: #DC280B;
}

.Container_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
}

.desc {
    width: 70%;
    text-align: center;
    font-size: 1.2rem;
    padding: 0 4rem;
}

.Lower_red {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #DC280B;
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    padding: .8rem;
}

.Lower_red b {
    background-color: #DC280B;
    padding: 0 .3rem;
}

@media(width<=1280px) {
    .Container_head {
        font-size: 4rem;
        line-height: 4rem;
    }

    .desc {
        font-size: 1rem;
    }

    .Lower_red {
        font-size: .8rem;
    }
}

@media(width<=1024px) {
    .Container_head {
        font-size: 3rem;
        line-height: 3rem;
    }

    .Container_image_one img {
        height: 25rem;
    }

    .desc {
        margin-bottom: 2rem;
    }
}

@media(width<=820px) {
    .Container {
        flex-direction: column;
    }

    .Container_head {
        font-size: 4.5rem;
        line-height: 4.5rem;
    }

    .Container_desc {
        width: 100%;
    }

    .desc {
        width: 100%;
        text-align: left;
        padding: 0 2rem;
        padding-top: 1rem;
    }
}

@media(width<=640px) {
    .Container {
        padding: 0 1rem;
        gap: 0;
    }

    .Container_head {
        font-size: 3rem;
        line-height: 3rem;
    }

    .Container_image_one img {
        height: 20rem;
    }
}

@media(width<=412px) {
    .Container_image_one img {
        height: 15rem;
    }

    .desc {
        margin-bottom: 0rem;
    }

    .Lower_red {
        font-size: .6rem;
    }
}

@media(width<=360px) {
    .Container_head {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .Container_image_one img {
        height: 12rem;
    }

    .desc {
        padding: 0 1rem;
    }
}

@media(width<=300px) {
    .Container_head {
        font-size: 2rem;
        line-height: 2rem;
    }

    .Container_image_one img {
        height: 10rem;
    }

    .Container_img_two {
        font-size: 1rem;
    }

    .Container_button_one,
    .Container_button_two {
        padding: .5rem;
        font-size: .8rem;
    }

    .Container_button {
        gap: 1rem;
    }

    .desc {
        font-size: .8rem;
    }

    .Lower_red {
        font-size: .5rem;
    }
}