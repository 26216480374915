.overlay-mains {
  /*Display stuff*/
  display: flex;
  align-items: center;
  /*Positioning stuff*/
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 700;
  /*Box model stuff*/
  width: 100%;
  height: 100%;
  /*Miscellaneous*/
  backdrop-filter: blur(5px) brightness(0.5);
}

.tabs_container {
  /*Display stuff*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;


}

.tab {
  /*Box model stuff*/
  margin: 0.25rem;
  /*Typography stuff*/
  font-family: "Noto Sans";
  font-size: 0.9rem;
  font-weight: 600;
  color: #696969;
}

.direction {
  /*Typography stuff*/
  font-size: 1.875rem;
  color: rgb(255 255 255);
  /*Miscellaneous*/
  line-height: 2.25rem;
}

.direction:hover {
  /*Typography stuff*/
  color: rgb(255 138 76);
}

.overlay-mains img {
  /*Display stuff*/
  display: block;
  /*Positioning stuff*/
  z-index: 800;
  /*Box model stuff*/
  max-width: 80%;
  max-height: 99%;
  margin: 60px auto;
  /*Miscellaneous*/
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.dismiss {
  /*Positioning stuff*/
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  /*Typography stuff*/
  font-size: 30px;
  color: #ffffff;
  /*Miscellaneous*/
  cursor: pointer;
}

.galleryImages {
  /*Display stuff*/
  display: flex;
  align-items: center;
  justify-content: center;
  /*Box model stuff*/
  min-width: 100px;
  height: 300px;
  min-height: 100px;
  border-radius: 0.5rem;
  background-color: black;
  /*Miscellaneous*/
  overflow: hidden;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.397);
}

.gallery-setter {
  /*Display stuff*/
  flex-direction: column;
  align-items: center;
  /*Positioning stuff*/
  position: absolute;
  z-index: 10;
  /*Typography stuff*/
  font-size: 1.875rem;
  color: rgb(255 255 255 / 0.7);
  /*Miscellaneous*/
  line-height: 2.25rem;
}

.galleryImages img {
  /*Display stuff*/
  display: block;
  /*Box model stuff*/
  width: 100%;
  min-width: 250px;
  height: 100%;
  min-height: 250px;
  /*Typography stuff*/
  color: white;
  /*Manipulations stuff*/
  transform: scale(1.1);
  transition: all 0.2s;
  /*Miscellaneous*/
  object-fit: cover;
  object-position: center;
  overflow: inherit;
}

.galleryImages:hover img {
  /*Manipulations stuff*/
  transform: scale(1.2);
}