.img {
    width: 100%;
    max-height: 500px;
    margin: 20px auto;
    object-fit: contain;
    background: #7734bc;
}

.blog_container p {
    max-width: 780px;
    width: 80vw;
    margin: auto;
    text-align: justify;
    

}

.blog_container p .quote {
    border-left: 5px solid #7734BC;
    padding: 3px 0px 3px 30px;
    font-style: italic;

}

.blog_container p .quote .quote_name {
    font-weight: 500;
}