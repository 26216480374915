* {
  padding: 0;
  margin: 0;
}

.first_view_container {
  padding: 0 2rem;
  background-color: #ffffff;
}

.fv_img {
  width: 20rem;
  display: block;
  margin: auto;
  margin-top: 0rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    padding: 0 2rem;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 35rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }
}

/* Second Section Styles */

.second_view_container {
  padding: 2rem;
  background-color: #fdfdfd;
}

.sv_heading {
  font-size: 1.95rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  text-align: center;
}

.sv_heading span {
  color: #f78c40;
}

.sv_tagline {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.company_logo {
  width: 3rem;
}

.company_name {
  margin-left: 0.75rem;
  text-align: right;
  font-size: 0.95rem;
}

.card {
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #404040;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
}

@media only screen and (min-width: 568px) {
  .sv_cards_container {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
  }

  .card {
    margin: 0.5rem 0rem;
  }
}


@media only screen and (min-width: 1000px) {
  .sv_cards_container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
  }

  .card {
    margin: 0.5rem 0rem;
  }

  .second_view {
    max-width: 1300px;
    margin: auto;
    /* margin-top: 2.5rem; */
    margin-bottom: 2.5rem;
  }

  .sv_texts {
    margin-bottom: 2rem;
    width: 60%;
  }

  .sv_heading {
    font-size: 2.25rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    text-align: left;
  }

  .sv_tagline {
    font-size: 1rem;
    width: 90%;
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}

@media only screen and (min-width: 1300px) {
  .sv_cards_container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    column-gap: 0.75rem;
  }

  .card {
    margin: 0.5rem 0rem;
  }
}

/* Third View Styles */
.third_view_container {
  padding: 2rem;
  background-color: #fdfdfd;
}

@media only screen and (min-width: 1000px) {

  .third_view {
    max-width: 1300px;
    margin: auto;
    margin-bottom: 2.5rem;
  }
}

.tcard_img {
  width: 100%;
  max-width: 20rem;
  object-fit: cover;
  border-radius: 5px;
  margin: 1rem 0rem;
}

.tcard_description {
  font-family: "Poppins", sans-serif;
  /* width: 90%; */
  font-size: 0.9rem;
}

.tcard_name {
  font-size: 1.35rem;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}

.tcard_date {
  font-size: 0.9rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #303030;
  margin-top: .5rem;
}

.tv_cards_container {
  margin-top: 2rem;
}

.tv_cards {
  margin: 5rem auto 0;
  max-width: 20rem;
}

@media only screen and (min-width: 768px) {
  .tv_cards_container {
    display: grid;
    grid-template-columns: 49% 49%;
    row-gap: 1.5rem;
    columns: 1.5rem;
    justify-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1000px) {
  .tcard_img {
    width: 100%;
    border-radius: .5rem;
    margin: 0 0 1rem;
  }

  .tv_cards_container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    row-gap: 1.5rem;
    columns: 1.5rem;
  }
}