.ContactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: -1rem;
  padding: 3rem;
  background-color: #f5f5f5;
}

.Contact img {
  height: 3rem;
}

.ContactList {
  width: 70%;
  display: flex;
}

.list {
  width: 100%;
}

.ContactHead {
  color: #1c1cf0;
  font-weight: 700;
  border-bottom: 1px solid #1c1cf0;
}

.ContactList ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.ContactList ul li {
  font-size: 0.9rem;
  color: #3f3f46;
  line-height: 1.1rem;
}

@media (width<=500px) {
  .ContactUs {
    flex-direction: column;
    padding: 1.5rem;
    padding-top: 3rem;
  }

  .ContactList {
    width: 100%;
  }
}
