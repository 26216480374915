.head {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.875rem;
  margin-top: -0.2rem;
}

.tv_texts {
  margin: 2rem;
}

.tv_mainheading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #303030;
}

.tv_mainheading span {
  color: #f78c40;
}

.tv_tagline {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .tv_texts {
    max-width: 1300px;
    margin: auto;
  }

  .tv_tagline {
    width: 50%;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  .tv_mainheading {
    width: 80%;
    font-family: "Noto Sans", sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-bottom: 1rem;
    color: #303030;
  }
}
