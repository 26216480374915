p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 999px) {
  .joinbutton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.firstviewmain_container {
  padding-top: 3rem;
  background-color: #f2f3f9;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #f2f3f9 15px
    ),
    repeating-linear-gradient(#fdf1e755, #fdf1e7);
}

.first_view {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.fv_texts {
  text-align: center;
}

.fv_image {
  display: block;
  margin: auto;
  width: 22rem;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
  /* color: #5C9AE5; */
}

.fv_tagline {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  column-gap: 0.5rem;
}

.primary,
.secondary {
  background-color: #f78c40;
  /* background-color: #5C9AE5; */
  padding: 0.7rem 2.75rem;
  border: none;
  margin-top: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  border-radius: 5px;
}

.secondary {
  background-color: #ffffff;
  color: #f78c40;
  /* color: #5C9AE5; */
  padding: 0.6rem 2rem;
  border: 2px solid #f59d5e;
  /* border: 2px solid #5C9AE5; */
}

@media only screen and (min-width: 1000px) {
  .firstview_container {
    max-width: 1000px;
    display: block;
    margin: auto;
  }

  .fv_image {
    display: block;
    margin: auto;
    width: 55rem;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.5rem;
    line-height: 4.55rem;
    font-weight: 600;
  }

  .fv_tagline {
    font-size: 1.1rem;
  }
}

/* Supporter View */

.supportersview_container {
  margin: 0 2rem;
  margin-top: 3rem;
}

.supporter_heading {
  line-height: 2rem;
  font-size: 1.75rem;
  font-weight: 600;
  color: #303030;
  margin-bottom: 1rem;
}

.supporter {
  margin: 0.5rem 0.5rem;
  width: 2.5rem;
}

.gsupporter {
  margin: 0.5rem 0.5rem;
  width: 3.25rem;
}

.supporter_heading span {
  color: #f78c40;
}

.supporters {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

@media only screen and (min-width: 1000px) {
  .supportersview_container {
    max-width: 1000px;
    display: block;
    margin: auto;
  }

  .supporter_heading {
    margin-top: 4rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
    font-size: 2.2rem;
    text-align: center;
  }

  .supporter {
    margin: 0.5rem 0.5rem;
    width: 4.55rem;
  }

  .gsupporter {
    margin: 0.5rem 0.5rem;
    width: 5.75rem;
  }

  .supporters {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto auto auto auto auto;
    justify-content: space-evenly;
  }
}

/* Second Section Styles */

.secondviewmain_container {
  padding: 2rem;
  padding-top: 2rem;
  padding-bottom: 0rem;
}

.sv_mainheading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #303030;
}

.sv_mainheading span {
  color: #f78c40;
}

.sv_tagline {
  margin-top: 0rem;
  font-family: "Poppins", sans-serif;
}

.sv_point {
  margin: 2rem 0;
}

.svp_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.svp_text {
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
  text-align: center;
}

.svp_image {
  width: 3.15rem;
  display: block;
  margin: auto;
}

@media screen and (max-width: 999px) {
  /* .svp_image {
    width: 3.15rem;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  } */

  .svp_heading {
    position: relative;
    text-align: center;
  }
}

@media only screen and (min-width: 1000px) {
  .secondviewmain_container {
    max-width: 1300px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .sv_mainheading {
    font-size: 2.75rem;
    margin-bottom: 0.5rem;
  }

  .sv_points {
    margin-top: 4rem;
    display: flex;
  }

  .sv_point {
    margin: 1rem;
  }

  .sv_tagline {
    width: 60%;
    display: block;
    margin: auto;
    font-size: 1.1rem;
  }

  .svp_text {
    font-family: "Poppins", sans-serif;
    font-size: 1.02rem;
  }
}

/* Thrid View */

.thirdviewmain_container {
  padding-top: 2rem;
}

.third_view {
  padding: 1rem 2rem;
  padding-top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.tv_mainheading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #303030;
}

.tv_mainheading span {
  color: #f78c40;
}

.tv_tagline {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
}

.tv_points {
  margin-top: 1rem;
}

.tv_point {
  margin: 1.5rem 0;
}

.tvp_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.45rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #f78c40;
}

.tvp_text {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

@media only screen and (min-width: 1000px) {
  .thirdviewmain_container {
    max-width: 1300px;
    margin: auto;
  }

  .third_view {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .tv_tagline {
    width: 80%;
  }

  .tv_mainheading {
    width: 80%;
    font-family: "Noto Sans", sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    color: #303030;
  }

  .tvp_heading {
    font-size: 1.5rem;
  }

  .tvp_text {
    font-size: 1rem;
  }
}

.join_view {
  margin: 0 2rem;
}

.jv_content {
  margin-top: 0.5rem;
  max-width: 35rem;
  font-family: "Poppins", sans-serif;
}

.jv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  max-width: 43rem;
  font-weight: 700;
  color: #303030;
}

.jv_heading span {
  color: #f78c40;
}

.highlight_text {
  color: #f78c40;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  /* margin: 1rem auto 0; */
  /* text-align: center; */
  width: 95%;
}

.highlight_text span {
  color: #303030;
  font-weight: 400;
}

@media screen and (min-width: 600px) {
  .join_img {
    height: 35rem;
    display: block;
    margin: auto;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 599px) {
  .join_img {
    height: 30rem;
    display: block;
    margin: auto;
    margin-top: 3rem;
  }
}

.joinviewmain_container {
  padding-top: 2rem;
}

@media only screen and (min-width: 1000px) {
  .joinviewmain_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 2rem;
  }

  .jv_texts {
    width: 60%;
  }

  .jv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    color: #303030;
  }

  .join_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .highlight_text {
    font-size: 1rem;
    width: 55%;
  }

  .join_img {
    display: block;
    height: 50rem;
    left: 20%;
  }

  .jv_content {
    width: 90%;
  }
}

/* Count View Styles */

.count_view {
  margin: 2rem;
}

.cv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #303030;
}

.cv_heading span {
  color: #f78c40;
}

.cv_text {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.countcontainer {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 2rem;
  gap: 1rem;
}

.count {
  margin: 0.5rem 0;

  text-align: center;
}

.cvc_heading {
  font-weight: 600;
  color: #f78c40;
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
}

.cvc_text {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: -0.2rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .countviewmain_container {
    max-width: 1300px;
    margin: auto;
  }

  .count_view {
    margin: 4rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left_side {
    width: 50%;
  }

  .right_side {
    width: 50%;
  }

  .cv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 700;
    color: #303030;
  }

  .countcontainer {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    row-gap: 0rem;
    column-gap: 2.5rem;
    margin-top: 0rem;
  }

  .count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cvc_heading {
    font-weight: 600;
    color: #f78c40;
    font-family: "Noto Sans", sans-serif;
    font-size: 3.25rem;
  }

  .cv_text {
    width: 90%;
  }

  .cvc_text {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: -0.2rem;
    font-family: "Poppins", sans-serif;
  }
}

.iframevideodesk {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .iframevideodesk {
    display: block;
  }

  .iframevideomob {
    display: none;
  }
}

/* Chart View Styles */

.chart_view_container {
  padding-top: 2rem;
}

.chart_view {
  padding: 1rem 2rem;
  padding-top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.chart_img {
  margin-top: 2rem;
  width: 20rem;
  margin-left: 50%;
  transform: translate(-50%);
}

@media only screen and (min-width: 768px) {
  .chart_img {
    margin-top: 2rem;
    width: 28rem;
  }
}

@media only screen and (min-width: 1000px) {
  .chart_view_container {
    max-width: 1300px;
    margin: auto;
  }

  .chart_view {
    margin: 4rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cv_texts {
    width: 48%;
  }

  .chart_img {
    width: 32rem;
  }
}

@media only screen and (min-width: 1000px) {
  .chart_view {
    margin: 4rem 0rem;
  }
}

/* Testimonials View Styles */

.testimonial_view_container {
  padding-top: 2rem;
}

.testimonial_view {
  padding: 1rem 2rem;
  padding-top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.test_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #303030;
}

.test_heading span {
  color: #f78c40;
}

.test_content {
  font-family: "Poppins", sans-serif;
}

.testmonial_card_container {
  margin-top: 2rem;
}

.testmonial_card {
  text-align: left;
  margin-top: 1rem;
}

.tperson_img {
  border-radius: 5px;
  margin-bottom: 1rem;
}

.tperson_name {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.25rem;
  color: #f78c40;
  font-weight: 500;
}

.tperson_designation {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.tcard_row {
  padding: 4rem;
}

@media only screen and (min-width: 1000px) {
  .testimonial_view_container {
    max-width: 1300px;
    margin: auto;
  }

  .tcard_row {
    display: flex;
    flex-direction: row;
    padding: 0 6rem;
    margin-top: 3rem;
  }

  .testimonial_view {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
  }

  .testmonial_card {
    margin-top: 0rem;
    margin-right: 5rem;
    text-align: left;
  }

  .testmonial_card_container {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
  }

  .test_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 3.25rem;
    font-weight: 700;
    color: #303030;
  }

  .test_content {
    width: 80%;
  }
}

.karma_img {
  max-width: 20rem;
}

@media screen and (min-width: 600px) {
  .karma_img {
    max-width: 25rem;
  }

  .karma_img {
    display: block;
    margin: auto;
    margin-top: 2rem;
  }
}

.mu_story {
  position: relative;
  padding-bottom: 25.25%; /* 16:9 aspect ratio for responsive video */
  overflow: hidden;
  width: 48.25%;
}

.mu_story iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 998px) {
  .mu_story {
    margin: auto;
    margin-top: 1rem;
    width: 49%;
  }
}
