@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
}

@font-face {
  font-family: "Nasa";
  src: url("./fonts/Nasa.ttf") format("truetype");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.text-lg a:hover {
  color: black;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.swiper-button-next {
  color: #ff8a4c !important;
}
.swiper-button-prev {
  color: #ff8a4c !important;
}
.swiper-pagination-bullet-active {
  background-color: #ff8a4c !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 2rem !important;
}
/* @media only screen and (min-width: 1000px){
  .swiper-button-next:after {
      margin-right: 500px !important;
    }
  
    .swiper-button-prev:after {
      margin-left: 500px !important;
    }
} */
