.first_view_container {
  padding: 2rem;
  background-color: #fdfdfd;
}

.fv_img {
  width: 20rem;
  display: block;
  margin: auto;
  margin-top: 0rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.primary {
  background-color: #f78c40;
  padding: 0.7rem 2rem;
  border: none;
  margin-top: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    padding: 0rem 3rem;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 33rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }

  .fv_tagline span {
    color: #f78c40;
    font-weight: bold;
  }
}

/* Second Section Styles */
.section {
  margin: 2rem 0;
}

.section_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section {
  max-width: 35rem;
}

.section_header {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  color: #f78c40;
}

.section_content,
.section_contentpara {
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    padding: 0rem 3rem;
  }

  .section_header {
    font-size: 2rem;
  }

  .section_contentpara {
    width: 60%;
  }

  .second_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }
}

/* Description View Container */

.description_view_container {
  padding-top: 2rem;
}

@media only screen and (min-width: 1000px) {
  .description_view_container {
    padding: 0rem 3rem;
  }

  .description_view {
    max-width: 1300px;
    margin: auto;

    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }
}
