* {
  padding: 0;
  margin: 0;
}

ol {
  list-style: decimal;
}

.first_view_container {
  padding: 2rem;
  background-color: #fdfdfd;
}

.fv_img {
  width: 20rem;
  display: block;
  margin: auto;
  margin-top: 0rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.primary {
  background-color: #f78c40;
  padding: 0.7rem 2rem;
  border: none;
  margin-top: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    padding: 0rem 3rem;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 33rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }

  .fv_tagline span {
    color: #f78c40;
    font-weight: bold;
  }
}

/* Second View Styles */

.sv_heading {
  font-size: 1.95rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  text-align: center;
}

.sv_heading span {
  color: #f78c40;
}

.sv_tagline {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.second_view_container {
  margin: 2rem;
}

.sv_steps {
  /* margin: 0 1rem; */
  font-weight: 400;
}

.step {
  max-width: 25rem;
  font-size: 1.05rem;
  padding: 1rem;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
}

.sv_steps {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
}

.step_number {
  font-size: 1.25rem;
  color: #f78c40;
  font-family: "Noto Sans";
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    max-width: 1300px;
    margin: auto;
    margin-bottom: 5rem;
  }
  .sv_heading {
    font-size: 2.25rem;
    text-align: left;
  }

  .sv_tagline {
    text-align: left;
    font-size: 1rem;
    max-width: 40rem;
  }
}
