.footer{
    width: 100%;
    height: 129px;
    background: #9765FF;
    display: grid;
    place-items: center;
    padding-bottom: 100px;
    margin-top: 3rem;
}

.footer div{
    width: 50%;
    height: 88px;
    background: #FF7D40;
    display: grid;
    
    place-content: center;
    position: relative;
    top: -40px;
}

.footer div p{
    color: #FFF;
    font-family: Raleway;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.footer p{
    color: #EBD7FF;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 14.023px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.519px;
}

@media (width<850px) {
    .footer div p{
        font-size: 16px;
    }

    .footer div{
        height: 70px;
    }

    .footer p{
        font-size: 10px;
    }
}

@media (width<500px) {
    .footer{
        height: 80px;
    }
    .footer div p{
        font-size: 10px;
    }

    .footer div{
        height: 60px;
        width: 60%;
    }

    .footer p{
        font-size: 10px;
    }
}