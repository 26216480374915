@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
}

.EnablersPage {
  width: 100%;
  padding: 2rem 0;
  border-radius: 0 0 50px 50px;
  background: #ece3ff;
  margin-bottom: 4rem;
  background-image: url("./assests/wire.webp");
  background-repeat: no-repeat;
  background-position: 0 -1100px;
}
