* {
  padding: 0;
  margin: 0;
}

.first_view_container {
  padding: 0 2rem;
  background-color: #ffffff;
}

.fv_img {
  width: 20rem;
  display: block;
  margin: auto;
  margin-top: 0rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  /* .first_view_container {
    padding: 3rem;
  } */

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 2.5rem;
    margin-bottom: 2.5rem; */
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 35rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }
}

/* Second View Styles */
.second_view_container {
  padding: 2rem;
  padding-top: 0rem;
  background-color: #ffffff;
}

/* .second_view {
  margin-top: 2rem;
} */

.sv_heading {
  font-size: 1.75rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}

.sv_heading span {
  color: #f78c40;
}

.sv_tagline {
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
}

.partners_view {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  align-content: space-between;
}

.partners_view a {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .partners_view {
    grid-template-columns: 50% 50%;
  }
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    padding: 2rem;
    max-width: 1300px;
    margin: auto;
  }

  .partners_view {
    grid-template-columns: 30% 30% 30%;
  }

  /* .second_view {
    margin-top: 5rem;
  } */

  .sv_texts {
    margin-bottom: 2rem;
    width: 40%;
  }

  .sv_heading {
    font-size: 2.25rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
 
    
  }

  .sv_tagline {
    font-size: 1rem;
    width: 90%;
    font-family: "Poppins", sans-serif;
   
  }
}

@media only screen and (min-width: 1300px) {
  .second_view_container {
    padding: 0rem;
  }

  .partners_view {
    grid-template-columns: 25% 25% 25% 25%;
  }
}