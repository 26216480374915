.menu_bar {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    padding: 1.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
    z-index: 1;
    transition: all .5s ease-in-out;
}

.menu_bar a {
    background-color: #fff;
    font-weight: 700;
    color: #000;
}

.logo img {
    height: 2rem;
    background-color: #fff;
}

.menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    gap: 2rem;
    background-color: transparent;
}

.pre_rg a {
    display: flex;
    align-items: center;
    gap: .5rem;
    border-radius: .8rem;
    padding: .8rem 1.5rem;
    background-color: #DC280B;
    color: white;
    border: none;
}

.pre_rg a img {
    background-color: #DC280B;
}

@media(width<=1280px) {
    .menu_bar {
        padding: 1rem 1.5rem;
    }

    .logo img {
        height: 1.5rem;
    }
}


@media(width<=820px) {
    .menu_bar {
        padding: 1rem;
    }

    .logo img {
        height: 1rem;
    }

    .menu {
        display: none;
    }

    .pre_rg a {
        border-radius: .4rem;
        padding: .5rem 1rem;
    }
}

@media(width<=300px){
    .menu_bar {
        padding: .5rem;
    }

    .logo img {
        height: .8rem;
    }

    .pre_rg a {
        padding: .5rem .8rem;
        font-size: .5rem;
    }
}