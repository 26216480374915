img {
  max-width: none;
}

.team-card {
  display: flex;
  column-gap: 1rem;
  margin: 1rem auto 0;
  max-width: 400px;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;

  font-family: "Poppins", sans-serif;

  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
}

.photo {
  border-radius: 0.5rem;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  object-position: top;
}

.team-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.5rem;
  width: 100%;
}

.team-card__name {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.team-card__linkedin {
  align-self: end;
}

.mobile-linkedin {
  display: none;
}

.linkedinimg {
  height: 1.75rem;
  width: 1.75rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 400px) {
  .team-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .team-card__image {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .photo {
    width: 5rem;
    height: 5rem;
  }

  .team-card__linkedin {
    display: none;
  }

  .mobile-linkedin {
    display: inline-block;
  }
}
.social_icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 5px;
}
.team-card__muid {
  font-size: 10px;
  font-weight: 400;
}
