@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@300;400&display=swap");

.campusCodeMulearn {
  position: absolute;
  right: calc(100% / 6);
  font-weight: 300;
  bottom: calc(6.3rem - 10px);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.7rem;
  letter-spacing: 0.25px;
}

.campusCodeYip {
  position: absolute;
  top: 11.5rem;
  font-size: 3.2rem;
  text-align: center;
}
