.main_container {
  max-width: 1300px;
  display: block;
  margin: auto;
}

.first_view_container {
  margin: 2rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.fv_heading {
  font-family: Noto Sans, sans-serif;
  font-size: 3.5rem;
  margin-top: -4rem;
  font-weight: 600;
  line-height: 5rem;
  color: #404040;
  text-align: center;
  line-height: 4rem;
}

.fv_tagline {
  font-family: "Poppins";
  font-size: 1.1rem;
  max-width: 30rem;
  text-align: center;
}

.fv_heading span {
  color: #f68227;
}

.fv_image {
  width: 20rem;
}

@media only screen and (min-width: 1000px) {
  .fv_image {
    width: 30rem;
  }

  .fv_tagline {
    text-align: left;
  }

  .first_view_container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .fv_heading {
    font-size: 4.5rem;
    max-width: 30rem;
    text-align: left;
    line-height: normal;
  }

  .ssheadingposttagline {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;

    width: 60%;
    display: block;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
  }

  .fstagline {
    font-size: 1.1rem;
    width: 60%;
    margin: auto;
  }

  .first__section {
    max-width: 1300px;
    flex-direction: row;
    margin: auto;
    margin-bottom: 2rem;
  }
}

.image_container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.image_container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.image_container img {
  aspect-ratio: 3/2;
  object-fit: contain;
  margin: 0 1rem;
  filter: grayscale(100%);
  width: 8rem;

  animation: move-left 15s linear infinite;
}

@keyframes move-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.counts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.count {
  font-family: "Noto Sans";
  font-weight: 600;
  color: #404040;
  font-size: 1.75rem;
}

.count_text {
  font-family: "Poppins";
  font-size: 1rem;
}

.hiring_count {
  margin: 1rem;
  max-width: 8rem;
  display: flex;
  text-align: center;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .hiring_count {
    margin: 2rem;
    margin-top: 1rem;
    margin-left: 0;
    text-align: left;
  }

  .count {
    font-size: 2.2rem;
  }

  .counts {
    justify-content: start;
  }
}

.second_view_container {
  margin: 2rem;
}

.hiring_name {
  font-family: "Noto Sans";
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: 600;
  color: #1e3760;
  text-align: center;
}

.hiring_tagline {
  font-family: "Poppins";
  font-size: 1rem;
  max-width: 40rem;
  margin-bottom: 1rem;
  text-align: center;
  display: block;
  margin: auto;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  font-family: sans-serif;
}

.cards_container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  row-gap: 1rem;
  column-gap: 1rem;
}

/* beyond_us_card */

.beyond_us_card {
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  background-image: url("./assets/starbg.png");
  border-radius: 5px;

  color: white;
  display: flex;
  flex-direction: column;
}

.text_logo {
  width: 15rem;
  font-family: "Poppins", sans-serif;
}

.text {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  font-weight: 500;
  max-width: 275px;
}

.text span {
  color: #f6e127;
}

.applynowbtn{
  margin-top: 1rem;
  width: 100%;
  background-color: #f6e127;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #1e3760;
}