.whoIsEnabler{
    width: 100%;
    display: grid;
    place-items: center;
    gap:1rem;
    margin: 1.5rem 0;
}

.whoIsEnabler h1{
    color: #9765FF;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.4px;
    letter-spacing: -2.88px;
    background-image: url('../../assests/Underline.webp');
    background-repeat: no-repeat;
    background-position: 200px 52px;
    background-size:200px ;
}
.whoIsEnabler h1 span{
    color: #FF7D40;
}

.whoIsEnabler p{
    width: 78%;
    color: var(--gray, #696984);
    font-family: Raleway;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.5%; 
    text-align: center;
}

@media (width<600px) {
    .whoIsEnabler h1{
        font-size: 38px;
        background-position: 150px 52px;
    }
    .whoIsEnabler p{
        font-size: 18px;
        text-align: left;
    }
}
@media (width<350px) {
    .whoIsEnabler h1{
        font-size: 30px;
        background-position: 120px 45px;
    }
    .whoIsEnabler p{
        font-size: 16px;
    }
}