.firstviewmain_container {
  opacity: 1;
  padding: 2rem 2rem 0;
}

.first_view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
}

.fv_texts {
  text-align: center;
}

.fv_illustration {
  display: block;
}

.fv_image {
  width: 20rem;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}



.fv_tagline {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
}

.select_wrapper {
  width: 100%;
  text-align: center;
}

.select {
  /* appearance: none; */
  border-radius: 0.5rem;
  width: 100%;
  max-width: 15rem;
  border: 2px solid #ffc297;
  padding: 0.5rem 1rem;
  /* background-image: url("../../../public/assets/common/down_arrow.svg") no-repeat right #ffc297; */

}

.team_group {
  margin: 5rem 2rem 0;
}

.team_title,
.sub_team_title {
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins';

}

.team_title {
  color: #f78c40;
  font-size: 2rem;
}

.sub_team_title {
  font-size: 1.5rem;
  margin-top: 3rem;
}

.team_desc {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.3;
}

.members_list {
  display: grid;
  margin: 1rem auto 0;
  max-width: 1300px;
  row-gap: 1rem;
}


@media only screen and (min-width: 768px) {
  .team_title {
    font-size: 2.5rem;
  }

  .sub_team_title {
    font-size: 2rem;
  }

  .team_desc {
    width: 70%;
  }

  .members_list {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .firstviewmain_container {
    margin: 6rem;
    max-width: 1300px;
    margin: auto;
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .fv_texts {
    width: 32vw;
    text-align: left;
  }

  .fv_image {
    width: 35rem;
  }



  .firstview_container {
    display: block;
  }

  .first_view {
    flex-direction: row;
  }

  .fv_illustration {
    display: block;
    margin-left: auto;
    text-align: right;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.5rem;
    line-height: 4.55rem;
    font-weight: 600;
  }

  .fv_tagline {
    font-size: 1.1rem;
    width: 80%;
  }

  .team_desc {
    width: 60%;
  }

  .members_list {
    grid-template-columns: 1fr 1fr 1fr;
  }

}