* {
  padding: 0;
  margin: 0;
}

.first_view_container {
  padding: 2rem;
  background-color: #ffffff;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_img {
  width: 18rem;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.readhide {
  color: #f78c40;
  cursor: pointer;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    padding: 0 3rem;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 35rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }
}

/* Second View Styles */

.sv_heading {
  font-size: 1.75rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #404040;
}

.sv_heading span {
  color: #f78c40;
}

.second_view_container {
  margin: 2rem;
}

.sv_tagline {
  font-family: "Poppins";
}

.sv_steps {
  /* margin: 0 1rem; */
  font-weight: 400;
}

.bootcamp {
  max-width: 25rem;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
}

.b_heading {
  font-size: 1.25rem;
  font-family: "Noto Sans";
  color: #f6822a;
}

.b_tagline {
  font-family: "Poppins";
  color: #404040;
  font-size: 0.9rem;
}

.b_button {
  background-color: #f6822a;
  color: #fff;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-family: "Noto Sans";
  border-radius: 5px;
  padding: 0.35rem 1.5rem;
}

.bootcamps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    max-width: 1300px;
    margin: auto;
    margin-bottom: 5rem;
  }

  .sv_texts {
    margin-bottom: 2rem;
    width: 60%;
  }

  .sv_heading {
    font-size: 2.25rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    text-align: left;
  }

  .sv_tagline {
    font-size: 1rem;
    width: 90%;
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}
